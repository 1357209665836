import React from "react";
import NavBar from "../../components/NavBar";
import { Link, useNavigate } from "react-router-dom";

const prices = [
  {
    plan : "Plan 1",
    name: "7 days trial with digital profile only",
    id: "trial",
    href: "/ascentnx",
    price: "Free",
    description: "No need to make payment",
    wamessage: `https://wa.me/971544012389?text=Hello Sanket I Have come across your profile through Know_Me App i want to Register for 7 Days free Trial`,

    features: [
      "Unlimited usage of all features",
      "Direct Calling & Whats App",
      "QR Code Sharing",
      "Save to Contact",
      "Clickable Links",
      "Social Media Accounts"

    ],
    disabled: false,
  },
  {
    plan : "Plan 2",    
    name: "Yearly subscription with digital profile only",
    id: "yearly",
    href: "/register",
    priceMonthly: "$ 50",
    description: "Get unlimited usage for your Profile",
    wamessage: `https://wa.me/971544012389?text=Hello Sanket I Have come across your profile through Know_Me App i want to Register Plan 2`,
    features: [
      "Unlimited usage of all features",
      "Direct Calling & Whats App",
      "QR Code Sharing",
      "Save to Contact",
      "Clickable Links",
      "Social Media Accounts"
    ],
  },
  // {
  //   plan : "Plan 3 * Top selling",    
  //   name: "Yearly subscription + NFC Card with AscentNext brand",
  //   id: "yearly",
  //   href: "/register",
  //   priceMonthly: "$ 70",
  //   description: "Get unlimited usage for your Profile",
  //   wamessage: `https://wa.me/971544012389?text=Hello Sanket I Have come across your profile through Know_Me App i want to Register for Plan 3`,
  //   features: [
  //     "Unlimited usage of all features",
  //     "Direct Calling & Whats App",
  //     "QR Code Sharing",
  //     "Save to Contact",
  //   ],
  // },
  // {
  //   plan : "Plan 4",    
  //   name: "Yearly subscription + NFC Card + Personalised brand design",
  //   id: "yearly",
  //   href: "/register",
  //   priceMonthly: "$ 45 *",
  //   description: "Get unlimited usage for your Profile",
  //   wamessage: `https://wa.me/971544012389?text=Hello Sanket I Have come across your profile through Know_Me App i want to Register for Plan 4`,
  //   features: [
  //     "Unlimited usage of all features",
  //     "Direct Calling & Whats App",
  //     "QR Code Sharing",
  //     "Save to Contact",
  //   ],
  // },  
];

function Pricing() {
  const navigate = useNavigate();
  return (
    <div class=" flex flex-col w-screen ">
      <div className="top-0 z-30 ">      { <NavBar />}</div>
      <div className="mt-20  flex overflow-hidden z-20">

        <div className="flex justify-center flex-col w-screen">
        <div className="flex justify-center mt-10">
        <Link
                  to="/"
                  className="p-4  text-black font-medium cursor-pointer mt-5 items-center flex justify-center underline rounded animate-bounce "
                >Konw More
         </Link>     
        </div>
          <div className="md:max-auto grid  grid-cols-1 gap-5  lg:grid-cols-2 md:p-1 w-screen mt-10 bg-green-200" >
            {prices.map((price) => (
              <div
                key={price.id}
                className="flex flex-col  rounded-1xl p-5 border-b-2 shadow-md items-center justify-center "
              >
                <div>
                <span className="py-10 text-sm  text-black underline">{price.plan}</span>
                  <h3
                    id={price.id}
                    className="text-base font-semibold leading-7 text-black h-auto"
                  >

                    {price.name}
                  </h3>
                  <div className="mt-4 flex  gap-x-2 ">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      {price.priceMonthly || price.price}
                    </span>
                    {price.priceMonthly && (
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        /Year /Profile
                      </span>
                    )}
                  </div>
                  <p className="mt-6 text-base leading-7 text-gray-600 ">
                    {price.description}
                  </p>
                  <ul
                    role="list"
                    className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                  >
                    {price.features.map((feature) => (
                      <li key={feature} className="font-semibold ">
                        {/* <AiOutlineCheck
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          /> */}
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <Link
                  to={price.wamessage}
                  className="bg-blue-500 text-white  font-medium cursor-pointer mt-5 items-center flex justify-center no-underline rounded"
                >
                  <span
                    // onClick={() => {
                    //   navigate(price.wamessage);
                    // }}
                    // aria-describedby={price.id}
                    // disabled={price.disabled}
                    className=" text-white p-3"
                  >
                    Subscribe Now
                    {/* {isLoading ? "Loading..." : "Buy Now"} */}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
