import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../components/NavBar";
import { QRCode, Form, Input, Button, Image, Divider, message } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loadersSlice";
import { getProfileById } from "../../../apicalls/profiles";
import html2canvas from 'html2canvas';

function PublicProfile() {
  const params = useParams();

  const dispatch = useDispatch();

  const [savecontact, setsavecontact] = useState();

  const [expdate, setexpdate] = useState();

  const contentRef = useRef(null);

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      //   console.log("params._id" + JSON.stringify(params));
      const response = await getProfileById(params.id);

      if (response.success) {
        dispatch(setLoading(false));
        //  console.log("params._id" + JSON.stringify(response));
        // console.log("params._id" + (response.data.owner.trialenddate));
        setProfileVal(response.data);

        // var todaysDate = new Date();
        // console.log("Today : ", todaysDate.toString() + "  exp ", response.data.owner.trialenddate);

        setexpdate(response.isExpired);
      } else {
        dispatch(setLoading(false));
        throw new Error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [profileval, setProfileVal] = useState({
    firstName: "xxxx",
    lastName: "xxxx",
    companyName: "xxxx",
    designation: "xxxx",
    website: "xxxx.com",
    email: "xxxx",
    trialenddate: "",
    facebook: "https://www.facebook.com/",
    tweeter: "xxxx",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com/",
    mobileno: "xxxx",
    imageurl:
      "https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg",
  });

  const qrloc = useLocation();


  const shareToWhatsApp = async () => {
  

    try {
      // Capture the element as a canvas
      const canvas = await html2canvas(contentRef.current);

      // Convert the canvas to a data URL (base64 string)
      const image = canvas.toDataURL('image/png');

      // WhatsApp sharing URL (for mobile devices, attach image as a public link for better compatibility)
      const whatsappMessage = `Check out my Digital ID Card!`;
      const encodedMessage = encodeURIComponent(whatsappMessage);
      const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

      // Log the generated image (for debugging or uploading to cloud storage)
      console.log('Generated Image Base64:', image);

      // Simulate a download (optional, for testing locally)
      const link = document.createElement('a');
      link.href = image;
      link.download = 'digital-id-card.png';
      link.click();

      // Redirect to WhatsApp with the message (and later include a link to the uploaded image)
      window.open(whatsappUrl, '_blank');
    } catch (error) {
      console.error('Error capturing the image:', error);
    }
  };

  const shareToWhatsAppClaud = async () => {
    dispatch(setLoading(true));

    try {
      const canvas = await html2canvas(contentRef.current,{
        useCORS:true
      });


      const blob = await new Promise(resolve => {
        canvas.toBlob(resolve, 'image/png');
      });
      
      const file = new File([blob], `${profileval?.firstName}.png`, { type: 'image/png' });

      if (navigator.share && navigator.canShare({ files: [file] })) {

        dispatch(setLoading(false));
        
        await navigator.share({
          files: [file],
          title: `${profileval?.firstName} Profile`,
          text: 'Check out my Profile from knowMe!'
        });


      } else {
        const url = URL.createObjectURL(blob);

        setsavecontact(url);
        dispatch(setLoading(false));


        const a = document.createElement('a');
        a.href = url;
        a.download = `${profileval?.firstName}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
     
      }
    } catch (error) {
      console.error('Error sharing:', error);
      alert('Error sharing image. Please try again.');
      dispatch(setLoading(false));

    }
  };


  const  SavetoContact = () => {
    // var contact = {
    //   name: "John Smith",
    //   phone: "555-555-5555",
    //   email: "john@example.com",
    // };

    dispatch(setLoading(true));
    const urlofcard = process.env.REACT_APP_DOMAIN + qrloc.pathname;



    var vcard1 =
      "BEGIN:VCARD\nVERSION:3.0\nFN:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nN:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nTEL;TYPE=work,voice:" +
      profileval?.mobileno +
      "\nURL:" +
      urlofcard +
      "\nEMAIL;TYPE=work:" +
      profileval?.email +
      "\nTITLE:" +
      profileval?.designation +
      "PROFILE:VCARD" +
      "\nORG:" +
      profileval?.companyName +
      "\nEND:VCARD";

    var todaysDate = new Date();
    //        console.log("Today : ", todaysDate.toString() + "  exp ", response.data.owner.trialenddate);

    var vcard =
      "BEGIN:VCARD" +
      "\nVERSION:3.0" +
      "\nREV:" +
      todaysDate.toString() +
      "\nN;CHARSET=utf-8:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      ";;;" +
      "\nFN;CHARSET=utf-8:" +
      profileval?.firstName +
      " " +
      profileval?.lastName +
      "\nORG;CHARSET=utf-8:" +
      profileval?.companyName +
      "\nTITLE;CHARSET=utf-8:" +
      profileval?.designation +
      "\nEMAIL;TYPE=work:" +
      profileval?.email +
      "\nTEL;PREF;WORK:" +
      profileval?.mobileno +
      "\nTEL;WORK:" +
      profileval?.mobileno +
      "\nNOTE:APP Developed by - Ascent Software Solutions \n for enquiry mail us at ascentnext@gmail.com" +
      "\nPHOTO;TYPE=JPEG;VALUE=URI:" +
      profileval?.imageurl +
      "\nURL:" +
      urlofcard +
      "\nEND:VCARD";

    // var blob = new Blob([vcard], { type: "text/vcard" });
    // var url = URL.createObjectURL(blob);

    const blob = new Blob([vcard], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);



    setsavecontact(url);



     const newLink = document.createElement('a');
     newLink.href = url;

     newLink.download = profileval?.firstName + '.vcf';
     newLink.textContent = profileval?.firstName;
     document.body.appendChild(newLink);


     newLink.click();

     // Remove the link after triggering the download
    document.body.removeChild(newLink);
    
    // Revoke the object URL
    window.URL.revokeObjectURL(url);



    // const newLink = document.createElement("a");
    // newLink.download = contact.name + ".vcf";
    // newLink.textContent = contact.name;
    // newLink.href = url;

    // newLink.click();

    dispatch(setLoading(false));


    // const shatedata = {
    //   title: "New Contacts",
    //   text: "Save contacts",
    //   files: [
    //     new File([blob], "newcontact.vcf", {
    //       type: "text/vcard",
    //     }),
    //   ],
    // };

    // if (navigator.share && navigator.canShare(shatedata)) {
    //   navigator
    //     .share({
    //       title: "New Contacts",
    //       text: "Save contacts",
    //       files: [
    //         new File([blob], "newcontact.vcf", {
    //           type: "text/vcard",
    //         }),
    //       ],
    //     })
    //     .then(() => {});
    // } else {
    //   const newLink = document.createElement("a");
    //   newLink.download = profileval?.firstName + ".vcf";
    //   newLink.textContent = profileval?.firstName;
    //   newLink.href = url;

    //   newLink.click();

    //   // window.close();
    // }





  };

  const [shorturl, setShorturl] = useState();

  const ProfileData = () => {
    let wametext = `https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}%0 I Have come across your profile through Know_Me App`;
    let altname = `Ascent Know_Me ${profileval?.firstName} ${profileval?.lastName}`;

    return (
      <div class="flex m-10 flex-col  justify-between items-center rounded-md relative shadow-lg w-full  lg:w-1/3 overflow-hidden z-30 md:p-10  bg-white">
        <div className="h-[26%] bg-blue-500  absolute w-full z-30 -m-10"></div>
        <div className="flex w-full">

        {/* <div className="h-14 bg-white absolute w-14 z-50 -m-10 justify-end flex items-end">
        <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt="know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-fit w-full h-full  shadow-xl bg-transparent object-center"

            

          />

        </div> */}

        </div>
        {/* <div class="profileimage relative z-40  mt-2"> */}
        <div class="mt-5 z-50 w-[85%] h-70 flex justify-center p-2 bg-transparent">
 
          <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            alt={altname}              //"know_me profile"
            // class="imgclass brightness-125"
            class=" brightness-125 object-cover rounded-3xl w-full h-full  shadow-xl bg-transparent object-center"

            

          />

        
        
        </div>


        <div class="workdetails ">
          <span class="username">
            {profileval?.firstName} {profileval?.lastName}
          </span>
          
          <span class="font-light text-sm">{profileval?.designation}</span>
          
          {profileval?.designation && <span class="companyname">{profileval?.companyName}</span>}
          {profileval?.mobileno && <span class="Mobile font-semibold mt-1">
            Mobile : {profileval?.mobileno}
          </span>}
        </div>

        <div class="webdetails">
          {profileval?.website && <div class="website">
                  <a href={profileval?.website} class="weburl">
            Website : {profileval?.website}
            </a>
          </div>}

          {profileval?.email && <div class="email mt-2">
      
            <span class="userwork">Email : {profileval?.email}</span>
          </div>}
        </div>

        <div class="socialtabs mt-5  p-2 w-full items-center justify-center  
        ">
          
{/* Facebook */}

          {profileval?.facebook && <div className=" flex flex-col gap-2 items-center "><a
            href={profileval?.facebook}
            target="_blank"
            class="link bg-[#1877F2] shadow-lg"
          >
            <i class="ri-facebook-fill"></i>
           
          </a>
          <span className="text-black text-xs">FB</span>
          </div>}



{/* //Instagram */}

          {profileval?.instagram && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.instagram}
            target="_blank"
            class="link bg-[#E4405F]"
          >
            <i class="ri-instagram-fill"></i>
           
          </a>
          <span className="text-black text-xs ">Insta</span>
          </div>}



{/* //linkdin */}
    
    
{profileval?.linkedin && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.linkedin}
            target="_blank"
            class="link bg-[#0a66c2]"
          >
            <i class="ri-linkedin-fill"></i>
           
          </a>
          <span className="text-black text-xs">Linkedin</span>
          </div>}




{/* Social links */}
{/* X twitter */}
          {profileval?.socialx && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.socialx}
            target="_blank"
            class="link bg-[#000000]"
          >
            {/* <i class="ri-twitter-x-fil text-white">x</i> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M10.4883 14.651L15.25 21H22.25L14.3917 10.5223L20.9308 3H18.2808L13.1643 8.88578L8.75 3H1.75L9.26086 13.0145L2.31915 21H4.96917L10.4883 14.651ZM16.25 19L5.75 5H7.75L18.25 19H16.25Z"></path></svg>
          </a>
          <span className="text-black text-xs">Twitter-X</span>
          </div>}

{/* Gmap */}
{profileval?.gmap && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.gmap}
            target="_blank"
            class="link bg-[#ffffff]"
          >
            {/* <i class="ri-map-pin-fill"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<path fill="#48b564" d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"></path><path fill="#fcc60e" d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"></path><path fill="#2c85eb" d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"></path><path fill="#ed5748" d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"></path><path fill="#5695f6" d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"></path>
</svg>           
          </a>
          <span className="text-black text-xs">G-Map</span>
          </div>}


          {/* <a
            href={savecontact}
            class="link ring-1 ring-[#000000]"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            <i class="ri-file-download-line bg-red-800"></i>
          </a>
 */}

        </div>

{/* //next line  */}
<div class="socialtabs mt-5 ">

  {/* Youtube */}
  {profileval?.youtube && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.youtube}
            target="_blank"
            class="link bg-[#ff0000]"
          >
            <i class="ri-youtube-fill"></i>
          </a>
          <span className="text-black text-xs">Youtube</span>
          </div>
                    }



{/* SnapChat */}
 {profileval?.snapchat && <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.snapchat}
            target="_blank"
            class="link bg-[#FFFC00]"
          >
            <i class="ri-snapchat-fill"></i>
          </a>
          <span className="text-black text-xs">Snapchat</span>
          </div>
                    }

{/* Tiktok */}

          {profileval?.tiktok && 
          <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.tiktok}
            target="_blank"
            class="link bg-[#010101]" 
          >
            <i class="ri-tiktok-fill"></i>
          </a>
          <span className="text-black text-xs">Tiktok</span>
          </div>}
{/* Wechat */}
{profileval?.wechat && 
          <div className=" flex flex-col gap-2 items-center"><a
            href={profileval?.wechat}
            target="_blank"
            class="link bg-[#09B83E]" 
          >
            <i class="ri-wechat-2-fill"></i>
          </a>
          <span className="text-black text-xs">Wechat</span>
          </div>}

</div>


        <div class="cta flex w-full  items-center justify-center">
          <a
            href={savecontact}
            class="bg-blue-600 shadow-xl p-3 px-8 text-white  rounded-md justify-center flex flex-row gap-2 items-center  font-light"
            onClick={SavetoContact}
            target="_blank"
            // download={savecontact}
            download
          >
            Save to contacts
            <i class="ri-file-download-line animate-bounce"></i>
          </a>


        </div>



        <div class="cta flex w-full p-2">
          <a href={"tel:" + profileval.mobileno} class="bg-blue-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline">
            Call
            <i class="ri-phone-fill animate-pulse"></i>
          </a>{" "}
          <a
            href={`https://wa.me/${profileval?.mobileno}?text=Hello ${profileval?.firstName}  We have come across your profile through Know_Me App`}
            class="bg-green-600 p-2 w-1/2 text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline"
          >
            WhatApp<i class="ri-whatsapp-fill animate-pulse"></i>
          </a>{" "}
        </div>
        <div class="footer">
          <a href="#" class="create">
            <span>Scan Qr code</span>
            <i class="ri-arrow-down-line animate-bounce"></i>
          </a>

            
          <span class="poweredby text-center flex items-center justify-center w-full text-blue-700">
            <a href="www.ascentnx.dev" className="no-underline text-black p-2">
            Powered by | Ascent Next | Since 2010
            </a>
          </span>
        </div>
      </div>
    );
  };

  const Profile1 = () => {
    // console.log("env : ", process.env.REACT_APP_DOMAIN);
    let qrloc = useLocation();
    // const shortUrl = require("turl");
    // shortUrl
    //   .shorten(qrloc)
    //   .then((res) => {
    //     setShorturl(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // shortUrl.short({ qrloc }, function (err, url) {
    //   console.log("url", url);
    //   setShorturl(url);
    // });

    const [usermobile, setusermobile] = useState("")


    const handleChange = (e) => {
      // console.log("mobile no of user ",e.target.value);
       setusermobile(e.target.value)
    }

    
    return (
      <div className="bg-neutral-100 w-screen">
        {!expdate && (
          <div class="flex flex-col justify-center items-center w-full p-4 bg-neutral-100">
            {/* {expdate ? <h1>Profile Expired</h1> : <h1>Not Expired</h1>} */}
            {/* <h1>Checking Expiry Date{profileval?.trialenddate}</h1> */}

            {<ProfileData />}
            <div className="qrData w-full  lg:w-1/3 flex  flex-col justify-center">
              
              <div ref={contentRef} className="Sharecarddetails shadow-2xl   bg-white ">
              <div className="flex items-center w-full justify-center bg-lime-500 h-[1px] shadow-md">
              <p className="text-white  text-sm hidden" >
                Save paper save trees</p>
            </div>

              <div className="justify-between flex items-center flex-row    border h-full ">
                <div className="flex flex-col justify-center items-center py-2 w-full  ">
                  <div className="cmplogo justify-center items-center ">
                  <img
            // src="https://res.cloudinary.com/sanketg/image/upload/v1685304129/TallyLogo23_vkyacc.jpg"
            src={profileval?.imageurl}
            crossOrigin="anonymous"
            alt={"AscentKnowMe_" + profileval?.firstName +" _ "+ profileval?.lastName}
            
            // class="imgclass brightness-125"
            class=" brightness-125 object-cover rounded-3xl w-[75px] h-[75px]  shadow-2xl object-center"

            

          />
                  </div>
                  <div className="flex w-full flex-col">
                  <h1 className="text-base font-semibold  mt-3 uppercase w-full text-center">    {profileval?.firstName} {profileval?.lastName}</h1>
                  <p className="items-start justify-start text-xs font-normal w-full text-center ">{profileval?.designation}</p>

                  </div>
                </div>
                <div className="border-r border-x-2 bg-orange-500  h-[220px] flex flex-col ">
                  <p className="h-full w-[1.5px]"></p>
                </div>
                <div className="flex flex-col justify-center items-center m-2 ">
                <QRCode size={140}
                  value={process.env.REACT_APP_DOMAIN + qrloc.pathname}
                ></QRCode>
                <h1 className="text-xs font-thin">Scan to view profile</h1>

                </div>
                {/* <div className="flex items-center w-full justify-center ">
              <p className=" text-lime-500 text-sm underline underline-offset-2 " >
                {profileval?.companyName}</p>
            </div> */}



              </div>
              <div className="flex items-center w-full justify-center bg-lime-500 p-1 shadow-md">
              <p className="text-white  text-sm " >
                Save paper save trees</p>
            </div>


              </div>

              <div className="flex items-center w-full justify-center p-3  mt-2">
              <button className="p-2 px-10 bg-blue-600 text-white border-0 rounded-md text-sm
              " onClick={shareToWhatsAppClaud}>
                Download and share</button>
            </div>


              <div className="mt-10 gap-4 flex flex-col p-2 " >
                <h3 className="font-light text-sm">Enter Destination Mobile no with country code</h3>
                <input value={usermobile} type="number"  placeholder="Mobile no with Country Code" className="w-full p-2 text-black font-bold"  onChange={handleChange}></input>
                <a
                target="_blank"
                href={`https://wa.me/${usermobile}?text=Hello check out my profile from Know_Me App @ %0a ${process.env.REACT_APP_DOMAIN + qrloc.pathname} `}
                class="bg-green-600 p-2  text-white rounded-md justify-center flex flex-row gap-2 items-center no-underline"
              >
                WhatApp Profile<i class="ri-whatsapp-fill animate-pulse"></i>
              </a>{" "}
              </div>
            </div>
          </div>
        )}
        {expdate && <div className="flex justify-center items-center">
          <h1 className="text-2xl font-thin">Profile Expired ! Renew your Subscription</h1></div>}
      </div>
    );
  };

  return (
    <div className="mainPageAct mt-33 lg:mt-20 ">
      <NavBar></NavBar>
      {/* <Tabs defaultActiveKey="2">
        <Tabs.TabPane tab="Profile" key="1">
          <CompanyProfiles />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preview" key="2">
          <ProfilePage />
        </Tabs.TabPane>
      </Tabs> */}

      <div className="justify-center flex  bg-neutral-100 w-screen">{<Profile1 />}</div>
    </div>
  );
}

export default PublicProfile;
